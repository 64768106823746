import $ from "jquery";

$(document).ready(function () {
  const submitUrl = "https://bbgroup.com.ua/mail.php";

  function getFormData($form) {
    var unindexed_array = $form.serializeArray();
    var indexed_array = {};

    $.map(unindexed_array, function (n, i) {
      indexed_array[n["name"]] = n["value"];
    });

    return indexed_array;
  }

  const $firstForm = $("#Consultation-form");
  const $secondForm = $("#wf-form--3");

  $firstForm.submit(function (e) {
    e.preventDefault();
    $.ajax({
      method: "POST",
      url: submitUrl,
      data: {
        name: getFormData($firstForm).name,
        phone: getFormData($firstForm).phone,
        from: "consultation"
      },
      complete() {
        $firstForm.hide();
      },
      success() {
        $("~ .w-form-done", $firstForm).show();
      },
      error() {
        $("~ .w-form-fail", $firstForm).show();
      }
    });
  });


  $secondForm.submit(function (e) {
    e.preventDefault();
    console.log(getFormData($secondForm));
    $.ajax({
      method: "POST",
      url: submitUrl,
      data: {
        from: 'contact',
        name: getFormData($secondForm).name,
        phone: getFormData($secondForm).phone,
        comment: getFormData($secondForm).comment
      },
      complete() {
        $secondForm.hide();
      },
      success() {
        $("~ .w-form-done", $secondForm).show();
      },
      error() {
        $("~ .w-form-fail", $secondForm).show();
      }
    });
  });
});
